import request from '@/utils/request'

// 列出所有墓地
export function findCemetery(params) {
  return request({
    url: `/cemeteries`,
    method: 'get',
    params: params
  })
}
// 更新墓地状态
export function updateCemeteryEffective(Id, data) {
  return request({
    url: `/cemeteries/${Id}/effective`,
    method: 'patch',
    data
  })
}
// 更新墓地信息
export function updateCemetery(Id, data) {
  return request({
    url: `/cemeteries/${Id}`,
    method: 'put',
    data
  })
}
// 创建墓地
export function createCemetery(data) {
  return request({
    url: `/cemeteries`,
    method: 'post',
    data
  })
}
